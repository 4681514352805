// JS

require('./init.js');
require('./ajax.js');
require('slick-carousel');
require('animate.css');
require('animejs');
const AOS = require('aos');
require('aos/dist/aos.css');
AOS.init();
// Sass
require('./../sass/index.sass');
